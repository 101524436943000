<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-text class="ion-text-left">
        <h1 class="text-bold font-32 ion-margin-start  welcome-text">
          Hello, <br>
          Welcome Back
        </h1>
      </ion-text>
      <div id="container">
        <ion-item>
          <ion-label position="floating">Username</ion-label>
          <ion-input v-model="username" type="text"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Password</ion-label>
          <ion-input @keyup.enter="login()" v-model="password" type="password"></ion-input>
        </ion-item>
        <br>
        <br>
        <ion-button expand="block" @click="login()">Login</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { getToken } from '@/helper/localstorageHelper'
import { apiLogin } from '@/services'
import { IonContent, IonPage, IonText, IonInput, IonItem, IonLabel, IonButton, toastController, loadingController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useIonRouter } from '@ionic/vue';

export default defineComponent({
  components: { IonInput, IonItem, IonLabel, IonContent, IonPage, IonText, IonButton },
  setup() {
    return {
      ionRouter: useIonRouter()
    }
  },
  data() {
    return {
      username: '',
      password: ''

    }
  },
  mounted() {
    if (getToken()) this.ionRouter.replace('/')
  },
  methods: {
    async login() {
      const dataUser = {
        username: this.username,
        password: this.password,
        grant_type: 'password'
      }
      const isLoading = await loadingController.create({})
      try {
        await isLoading.present() //to initiate loading
        const res = await apiLogin(dataUser)
        const toast = await toastController.create({
          message: "You are logged in",
          color: "dark",
          duration: 1000,
          position: 'top'
        });
        if (res) {
          await toast.present()
          await isLoading.dismiss()
        }
      }
      catch (err) {
        await isLoading.dismiss()
        const toast = await toastController.create({
          message: err.response.data,
          color: "dark",
          duration: 1000,
          position: 'top'
        });
        await toast.present()
      }


    }
  },
});
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 250px;
  padding: 16px;
}

.welcome-text {
  padding-top: 40px;
  padding-left: 8px;
}
</style>
