import axios from 'axios'
import { getToken } from './localstorageHelper'
import { logout } from './functionHelper'
import router from '@/router'

const axiosCustom = axios.create()
// axiosCustom.defaults.baseURL = 'https://spring-mongo-production.up.railway.app'
// axiosCustom.defaults.baseURL = 'http://localhost:8088'
axiosCustom.defaults.baseURL = 'https://bajong.vercel.app/'
const token = getToken()

if (token) {
  axiosCustom.defaults.headers.common["Authorization"] = token
}
axiosCustom.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error) {
    if (error.response.status === 401) {
      logout()
      router.push('/login')
    }
  }
  return Promise.reject(error)
})

export default axiosCustom
