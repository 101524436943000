import moment from 'moment'
import { getGlass, getMaterial, getProduct, getSeries, removeGlass, removeMaterial, removeProduct, removeSeries, setProduct, setSeries, setGlass, setMaterial, setLocationList, getLocationList, setLastUpdate, removeLastUpdate, removeLocationList } from './localstorageHelper'
import { apiProducts, apiAllSeries, apiAllGlass, apiAllMaterial, apiLocation } from '@/services'
import { dateFormatter } from './functionHelper'
import store from '@/store'

// DATA
let productList = getProduct() || []
let seriesList = getSeries() || []
let glassList = getGlass() || []
let materialList = getMaterial() || []
let locationList = getLocationList() || []


// FUNCTION

// PRODUCTS
export async function getProductApi() {
  if (!productList.length) {
    const products = await apiProducts()
    productList = products
    setProduct(products)
    return products
  } else return productList
}
export async function findProductByType(type) {
  const products = productList.filter(el => el.type === type)
  products.sort((a, b) => {
    if (a.series < b.series) { return -1; }
    if (a.series > b.series) { return 1; }
    return 0;
  })
  return products
}
export async function findProductById(id) {
  const products = productList.find(el => el.idProduct === id)
  return products
}

// SERIES
export async function getSeriesApi() {
  if (!seriesList.length) {
    const series = await apiAllSeries()
    seriesList = series
    setSeries(series)
    return series
  } else return seriesList
}

// GLASS
export async function getGlassApi() {
  if (!glassList.length) {
    const glass = await apiAllGlass()
    glassList = glass
    setGlass(glass)
    return glass
  } else return glassList
}
// MATERIAL
export async function getMaterialApi() {
  if (!materialList.length) {
    const material = await apiAllMaterial()
    materialList = material
    setMaterial(material)
    return material
  } else return materialList
}
// LOCATION
export async function getLocationApi() {
  if (!locationList.length) {
    const location = await apiLocation()
    locationList = location
    setLocationList(location)
    return location
  } else return locationList
}

// UPDATE DATA 
export async function updateData() {
  productList = []
  seriesList = []
  glassList = []
  materialList = []
  locationList = []
  await getLocationApi()
  await getProductApi()
  await getSeriesApi()
  await getMaterialApi()
  await getGlassApi()

  const lastUpdate = await dateFormatter(new Date())

  store.commit('setLastUpdate', lastUpdate)
  setLastUpdate(lastUpdate)
}

// REMOVE DATA
export async function removeData() {
  productList = []
  seriesList = []
  glassList = []
  materialList = []
  locationList = []
  removeLocationList()
  removeProduct()
  removeSeries()
  removeMaterial()
  removeGlass()
  removeLastUpdate()
}