import { getToken } from '@/helper/localstorageHelper'
import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue'
import Products from '../views/Products.vue'
import History from '../views/History.vue'
import ProductDetail from '../views/ProductDetail.vue'
import Login from '../views/Login.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/products',
    name: 'Products',
    component: Products
  },
  {
    path: '/history',
    name: 'History',
    component: History
  },
  {
    path: '/product/detail/:id',
    name: 'Product Detail',
    component: ProductDetail
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const token = getToken()

  if (to.path !== '/login' && to.path !== '/register' && to.path !== '/forgot-password' && to.path !== '/privacy') {
    if (!token) {
      next('/login')
    }
    else {
      next()
    }
  } else {
    next()
  }
})

export default router
