const LANGUAGE_KEY = 'LANG'
const TOKEN_KEY = 'token'
const USER_KEY = 'username'
const LOCATION = 'location'
const HISTORY = 'history'

const PRODUCT = 'delica_product'
const SERIES = 'delica_series'
const GLASS = 'delica_glass'
const MATERIAL = 'delica_material'
const MATERIAL_PRICE = 'delica_material_price'
const GLASS_PRICE = 'delica_glass_price'
const LOCATION_LIST = 'delica_location'

const LAST_UPDATE = 'delica_last_update'




// LANGUAGE

export function setLanguage (lang) {
  localStorage.setItem(LANGUAGE_KEY, JSON.stringify(lang))
}

export function getLanguage () {
  return JSON.parse(localStorage.getItem(LANGUAGE_KEY))
}

export function removeLanguage () {
  localStorage.removeItem(LANGUAGE_KEY)
}

// TOKEN

export function setToken (token) {
  localStorage.setItem(TOKEN_KEY, token)
}

export function getToken () {
  return localStorage.getItem(TOKEN_KEY)
}

export function removeToken () {
  localStorage.removeItem(TOKEN_KEY)
}

// USER

export function setUser (user) {
  const userData = JSON.stringify(user)
  localStorage.setItem(USER_KEY, userData)
}

export function getUser () {
  return JSON.parse(localStorage.getItem(USER_KEY))
}

export function removeUser () {
  localStorage.removeItem(USER_KEY)
}

// LOCATION

export function setLocation (location) {
  const locationData = JSON.stringify(location)
  localStorage.setItem(LOCATION, locationData)
}

export function getLocation () {
  const locationData = JSON.parse(localStorage.getItem(LOCATION))
  return locationData
}

export function removeLocation () {
  localStorage.removeItem(LOCATION)
}

// HISTORY
export function pushhistory (item) {
  const history = gethistory() || []
  if (history.length > 4) history.splice(-1)
  history.unshift(item)
  localStorage.setItem(HISTORY, JSON.stringify(history))
}

export function gethistory () {
  return JSON.parse(localStorage.getItem(HISTORY))
}

export function removehistory (idx?: any) {
  if (idx >= 0) {
    const history = gethistory()
    history.splice(idx, 1)
    localStorage.setItem(HISTORY, JSON.stringify(history))
  } else  localStorage.removeItem(HISTORY)
}

// PRODUCT
export function setProduct (series) {
  const seriesData = JSON.stringify(series)
  localStorage.setItem(PRODUCT, seriesData)
}

export function getProduct () {
  const productData = JSON.parse(localStorage.getItem(PRODUCT))
  return productData
}

export function removeProduct () {
  localStorage.removeItem(PRODUCT)
}

// SERIES
export function setSeries (series) {
  const seriesData = JSON.stringify(series)
  localStorage.setItem(SERIES, seriesData)
}

export function getSeries () {
  const seriesData = JSON.parse(localStorage.getItem(SERIES))
  return seriesData
}

export function removeSeries () {
  localStorage.removeItem(SERIES)
}

// MATERIAL
export function setMaterial (material) {
  const materialData = JSON.stringify(material)
  localStorage.setItem(MATERIAL, materialData)
}

export function getMaterial () {
  const materialData = JSON.parse(localStorage.getItem(MATERIAL))
  return materialData
}

export function removeMaterial () {
  localStorage.removeItem(MATERIAL)
}

// GLASS
export function setGlass (glass) {
  const glassData = JSON.stringify(glass)
  localStorage.setItem(GLASS, glassData)
}

export function getGlass () {
  const glassData = JSON.parse(localStorage.getItem(GLASS))
  return glassData
}

export function removeGlass () {
  localStorage.removeItem(GLASS)
}

// MATERIAL PRICE
export function setMaterialPrice (materialPrice) {
  const materialPriceData = JSON.stringify(materialPrice)
  localStorage.setItem(MATERIAL_PRICE, materialPriceData)
}

export function getMaterialPrice () {
  const materialPriceData = JSON.parse(localStorage.getItem(MATERIAL_PRICE))
  return materialPriceData
}

export function removeMaterialPrice () {
  localStorage.removeItem(MATERIAL_PRICE)
}

// GLASS PRICE
export function setGlassPrice (glassPrice) {
  const glassPriceData = JSON.stringify(glassPrice)
  localStorage.setItem(GLASS_PRICE, glassPriceData)
}

export function getGlassPrice () {
  const glassPriceData = JSON.parse(localStorage.getItem(GLASS_PRICE))
  return glassPriceData
}

export function removeGlassPrice () {
  localStorage.removeItem(GLASS_PRICE)
}

// LOCATION LIST
export function setLocationList (location) {
  const locationData = JSON.stringify(location)
  localStorage.setItem(LOCATION_LIST, locationData)
}

export function getLocationList () {
  const locationData = JSON.parse(localStorage.getItem(LOCATION_LIST))
  return locationData
}

export function removeLocationList () {
  localStorage.removeItem(LOCATION_LIST)
}

// LAST UPDATE

export function setLastUpdate (date) {
  localStorage.setItem(LAST_UPDATE, JSON.stringify(date))
}

export function getLastUpdate () {
  return JSON.parse(localStorage.getItem(LAST_UPDATE))
}

export function removeLastUpdate () {
  localStorage.removeItem(LAST_UPDATE)
}