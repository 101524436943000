
import { computed, defineComponent } from 'vue'
import { IonPage, IonGrid, IonButton, IonRow, IonCol, IonButtons, IonContent, IonHeader, IonTitle, IonToolbar, IonText, IonFooter, IonModal } from '@ionic/vue'
import { numericFormat } from '@/helper/functionHelper'
import { useStore } from 'vuex'

export default defineComponent({
  props: ['result'],
  components: { IonPage, IonGrid, IonButton, IonRow, IonCol, IonButtons, IonContent, IonHeader, IonTitle, IonToolbar, IonText, IonFooter, IonModal },
  emits: ["close"],
  setup() {
    const store = useStore()
    return{
      username: computed(() => store.state.user.email )
    }
  },
  data() {
    return {
      isResultModalOpen: false,
      subtotal: 0,
      total: 0
    }
  },
  methods: {
    numericFormat(number) {
      return numericFormat(number)
    },
    detail(isOpen) {
      this.isResultModalOpen = isOpen
    },
  },
  mounted() {
    const subtotal = (this.result.summary.total) * (111 / 100)
    this.subtotal = subtotal
    this.total = subtotal
    if (this.result.discount && this.result.discount.type === 'percent') this.total = this.subtotal - (this.subtotal * (Number(this.result.discount.price) / 100))
    if (this.result.discount && this.result.discount.type === 'harga') this.total = this.subtotal - Number(this.result.discount.price)
    if (this.result.tambahan) this.total += Number(this.result.tambahan.price)
  }
})
