import axios from '@/helper/axiosHelper'
import { urlEncoded } from '@/helper/functionHelper'
import { setToken, setLocation, setUser } from '@/helper/localstorageHelper'
import { Buffer } from 'buffer';
import { toastController } from '@ionic/vue';

window.Buffer = Buffer;
const apiLogin = async (dataUser) => {
  const username = "delica-client";
  const password = "delica-secret";
  const token = Buffer.from(`${username}:${password}`, "utf8").toString(
    "base64"
  )
  axios
    .post("/api/oauth/token", urlEncoded(dataUser), {
      headers: {
        Authorization: `Basic ${token}`
      }
    })
    .then(async res => {
      const token = 'Bearer ' + res.data.access_token
      axios.defaults.headers.common["Authorization"] = token
      const dataUser = await apiCurrentUser()
      setToken(token)
      setUser(dataUser)
      const locationData = await apiLocation()
      setLocation(locationData[0])
      location.replace('/home')
      return res.data
    })
    .catch(async (err) => {
      const toast = await toastController.create({
        message: err.response? err.response.data.error_description : 'error',
        color: 'danger',
        duration: 1500,
        position: 'bottom'
      });

      await toast.present();
      throw err
    })
}
const apiCurrentUser = async () => {
  return axios.get('/api/user/details')
    .then(response => {
      return response.data
    })
    .catch((err) => {
      throw err
    })
}
const apiAllSeries = async () => {
  return axios.get('/api/series')
    .then(response => {
      return response.data
    })
    .catch((err) => {
      throw err
    })
}
const apiAllGlass = async () => {
  return axios.get('/api/glass')
    .then(response => {
      return response.data
    })
    .catch((err) => {
      throw err
    })
}
const apiAllMaterial = async () => {
  return axios.get('/api/material')
    .then(response => {
      return response.data
    })
    .catch((err) => {
      throw err
    })
}
const apiProducts = async (type?: any) => {
  let params = {}
  if (type) {
    params = {
      type: type
    }
  }
  return axios.get('/api/product', {
    params: params
  })
    .then(response => {
      return response.data
    })
    .catch((err) => {
      throw err
    })
}
const apiProductDetail = async (id) => {
  return axios.get('/api/product/' + id)
    .then(response => {
      return response.data
    })
    .catch((err) => {
      throw err
    })
}
const apiLocation = async () => {
  return axios.get('/api/location')
    .then(response => {
      return response.data
    })
    .catch((err) => {
      throw err
    })
}

export {
  apiLogin,
  apiAllSeries,
  apiAllGlass,
  apiProducts,
  apiProductDetail,
  apiLocation,
  apiAllMaterial
}
