import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ion-padding" }
const _hoisted_2 = { class: "ion-no-margin text-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, { class: "ion-no-margin" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ion_img, {
          class: "full-width-img",
          src: _ctx.item.imageUrl || 'https://thumbs.dreamstime.com/b/no-thumbnail-image-placeholder-forums-blogs-websites-148010362.jpg'
        }, null, 8, ["src"])
      ]),
      _createVNode(_component_ion_card_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_text, { color: "primary" }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.item.type) + " S" + _toDisplayString(_ctx.item.series), 1)
            ]),
            _: 1
          }),
          _createElementVNode("small", null, _toDisplayString(_ctx.item.doorLeaves) + " Daun (" + _toDisplayString(_ctx.item.description) + ")", 1)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_button, {
            size: "small",
            expand: "block",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/product/detail/' + _ctx.item.idProduct)))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Hitung Harga")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}