import moment from 'moment';
import { removeToken, removeUser, removeLocation, removehistory } from './localstorageHelper'
import { removeData } from './dataHelper';

export function urlEncoded(obj) {
  const str = [];
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      str.push(
        encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
      )
    }
  }
  return str.join("&");
}
export function logout() {
  removeToken()
  removeUser()
  removeLocation()
  removehistory()
  removeData()
  location.reload()
}
export function numericFormat(number) {
  const formatter = new Intl.NumberFormat('id-ID', {
    style: 'decimal',
    minimumFractionDigits: 0
  })
  return formatter.format(number)
}

export async function sortingByName (data) {
  data.sort((a, b) => {
    const userA = a.name.toLowerCase(), userB = b.name.toLowerCase();
    if (userA < userB) //sort string ascending
      return -1;
    if (userA > userB)
      return 1;
    return 0; //default return value (no sorting)
  })
  return data
}

export async function dateFormatter (date: any) {
  return moment(date).format('YYYY-MM-DD')
}
export async function humanDateFormatter (date: any) {
  return moment(date).format('DD MMM YYYY')
}

