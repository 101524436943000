
import Vuex from 'vuex'
import { getLastUpdate, getLocation, getUser } from '../helper/localstorageHelper'

const state = {
  location: getLocation(),
  user: getUser(),
  lastUpdate: getLastUpdate(),
  notifUpdate: true

}
const mutations = {
  setLocation(state, value) {
    state.location = value
  },
  setDoneNotifUpdate(state) {
    state.notifUpdate = false
  },
  setLastUpdate(state, value) {
    state.lastUpdate = value
  }
}

export default new Vuex.Store({
  state,
  mutations
})
