
import { IonList, IonSearchbar, IonItem, IonGrid, IonButton, IonInput, IonRow, IonCol, IonButtons, IonBackButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonFooter, IonText, IonModal, IonImg, IonToggle, IonLabel, IonSegment, IonSegmentButton } from '@ionic/vue'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import store from '@/store'
import resultTemplate from '@/components/resultTemplate.vue'
import { pushhistory } from '@/helper/localstorageHelper'
import { sortingByName } from '@/helper/functionHelper'
import { findProductById, getGlassApi, getMaterialApi } from '@/helper/dataHelper'
export default defineComponent({
  components: { resultTemplate, IonList, IonSearchbar, IonItem, IonGrid, IonButton, IonInput, IonRow, IonCol, IonButtons, IonBackButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonFooter, IonText, IonModal, IonImg, IonToggle, IonLabel, IonSegment, IonSegmentButton },
  setup() {
    const router = useRoute()
    const productDetail = ref(null)
    const glass = ref([])
    const material = ref(null)
    const selectedGlass = ref('')
    const selectedGlassItem = ref('')
    const glassResults = ref([])
    const location = store.state.location


    onMounted(async () => {
      productDetail.value = await findProductById(router.params.id)
      const glassApi = await getGlassApi()
      glass.value = await sortingByName(glassApi)
      glassResults.value = glass.value.filter(el => el.price[location.location])
      const materialData = await getMaterialApi()
      const materialObj = {}
      materialData.map(el => {
        materialObj[el.idMaterial] = el
      })
      material.value = materialObj
    })

    return {
      selectedGlassItem,
      selectedGlass,
      productDetail,
      glass,
      material,
      glassResults,
      location
    }
  },
  data() {
    return {
      isResultModalOpen: false,
      openSelectModal: false,
      result: null,
      lebar: null,
      tinggi: null,
      fixGlassBottom: null,
      fixGlassTop: null,
      tambahan: {
        isActive: false,
        description: '',
        price: null
      },
      discount: {
        isActive: false,
        type: 'percent',
        description: '',
        price: null
      }
    }
  },
  methods: {
    hitung() {
      const summary = {
        material: [],
        total: 0
      }
      this.result = null

      this.productDetail.material.map(el => {
        if (el.idMaterial) {
          const item = {
            name: '',
            id: null,
            category: 'material',
            formula: null,
            rawformula:null,
            itemPrice: null,
            totalPrice: null
          }
          if (el.idMaterial === 'Kaca') {
            const glass = this.glass.find(e => e.idGlass === this.selectedGlass)
            item.name = glass.name
            item.itemPrice = glass.price[this.location.location]
            item.category = 'glass'
          }
          else {
            item.itemPrice = this.material[el.idMaterial].price[this.location.location]
            item.name = this.material[el.idMaterial].name
          }
          const formula = el.formula.replace('L', Number(this.lebar)).replace('T', Number(this.tinggi)).replace('A', Number(this.fixGlassTop)).replace('B', Number(this.fixGlassBottom))
          item.rawformula = el.formula
          item.formula = eval(formula)
          item.id = el.idMaterial
          item.totalPrice = item.itemPrice * item.formula
          summary.material.push(item)
          summary.total += item.totalPrice
        }
      })
      this.result = {
        summary: summary,
        lebar: this.lebar,
        tinggi: this.tinggi,
        fixGlassTop: this.fixGlassTop,
        fixGlassBottom: this.fixGlassBottom,
        productDetail: this.productDetail,
        location: this.location.location
      }
      if (this.tambahan.isActive) {
        this.result.tambahan = this.tambahan
      }
      if (this.discount.isActive) {
        this.result.discount = this.discount
      }
      pushhistory(this.result)
      this.openResultModal(true)
    },

    openResultModal(isOpen) {
      this.isResultModalOpen = isOpen
      if (!isOpen) this.result = null
    },
    pickerGlass(isOpen) {
      this.openSelectModal = isOpen
    },
    selectGlass(value) {
      this.pickerGlass(false)
      this.selectedGlass = value.idGlass
      this.selectedGlassItem = value
    },

    handleChange(event) {
      const query = event.target.value.toLowerCase();
      const result = this.glass.filter(el => el.name.toLowerCase().indexOf(query) > -1);
      this.glassResults = result.filter(el => el.price[this.location.location])
    },
  }
})
